import { createApp } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";
import App from "./App.vue";
import VueGtag from "vue-gtag";

const Home = () => import("./views/HomeView.vue");

let scrollHome = 0;

const app = createApp(App);

app.config.globalProperties.$projetos = []; //Courses DB

/* VUE ROUTER */
const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/",
            name: "home",
            components: {
                Screen: Home,
            }
        },
        {
            path: "/:pathMatch(.*)*",
            redirect: () => {
                return { path: '/', query: null }
            },
        }
    ],
    scrollBehavior() {
        if (router.currentRoute.value.path === "/") {
            return { top: scrollHome }
        }
        return { top: 0 };
    }
});
addEventListener('scroll', () => {
    if (router.currentRoute.value.path === "/") {
        scrollHome = window.scrollY;
    }
})
app.use(router);
app.use(VueGtag, {
    includes: [
        { id: '' }, //ADS
    ],
    config: { id: '' } //Analytics ou GTM
});
router.isReady().then(() => app.mount("#app"));